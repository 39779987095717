.svg{
    height: 16px;
    width: 16px;
    cursor: pointer;
    fill: #737373;
    transition: 140ms ease-in;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.svg:hover{
    fill: #000000;
}

.i_btn {
    color: #918F8F;
    font-size: 24px;
}