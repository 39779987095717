@import "main.scss";
@import "variables.scss";
@import "typography.scss";

html, body {
    height: 100%;
    font-family: "Open Sans", Arial, sans-serif !important;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.visible-test {
    display: block;
    visibility: visible;
}
.hide-test {
    display: none;
    visibility: hidden;
}
