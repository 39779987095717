.customAlertContainer {
    position: absolute;
    top: 20%;
    left: 65%;
    transform: translate(-50%, -50%);
    z-index: 10;
}

.customAlertChildrenContainer {
    display: flex;
    justify-content: space-around;
    width: 200px;
    margin: 0 auto;
}