.form {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    width: 368px;
    padding: 0 0 0 32px;
    font-size: 16px;
}

.select {
    appearance: none;
    background-image: url("../../assets/icons/down-arrow.svg");
    background-repeat: no-repeat;
    background-position-y: 50%;
    background-position-x: 96%;
    background-size: 12px;
    border: 1px solid var(--blueColor);
    border-radius: 5px;
    background-color: var(--whiteColor);
    outline: none;
    padding: 6px 15px;
    font-size: 15px;
}

.input {
    width: 308px !important;
}

.input::placeholder {
    font-family: "Open Sans", Arial, sans-serif;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 16px;
}

.inputLabel {
    margin: 20px 0 8px 0;
    font-family: "Open Sans", Arial, sans-serif;
    font-size: 12px;
    line-height: 16px;
    color: var(--greyColor);
}

.container {
    margin-top: 71px;
    display: flex;
    justify-content: flex-end;
    width: 468px;
    padding-right: 32px;
    padding-bottom: 32px;
}

.containerBtn {
    display: flex;
    justify-content: space-between;
    width: 198px;
}

.arrowFleep {
    background-image: url("../../assets/icons/up-arrow.svg");
    background-size: 20px;
}

.btn {
    font-family: 'Open Sans', sans-serif !important;
    font-size: 14px !important;
    font-weight: 600 !important;
    letter-spacing: 0 !important;
    line-height: 16px !important;
    width: 95px !important;
    height: 36px !important;
    background: #51A8F1 !important;
    border-color: #51A8F1 !important;
    color: var(--whiteColor) !important;
}

.btn:hover {
    background: #51A8F1 !important;
    color: var(--whiteColor) !important;
    border-color: #51A8F1 !important;
}


.btnOutline {
    font-family: 'Open Sans', sans-serif !important;
    font-size: 14px !important;
    font-weight: 600 !important;
    letter-spacing: 0 !important;
    line-height: 16px !important;
    width: 95px !important;
    height: 36px !important;
    color: var(--blueColor) !important;
    border-color: var(--blueColor) !important;
}

.btnOutline:hover {
    background: var(--blueColor) !important;
    color: var(--whiteColor) !important;
    border-color: var(--blueColor) !important;
}


.iconBtn {
    background: transparent;
    border: none;
    height: 20px;
    width: 20px;
    margin-right: 32px;
}

.title {
    font-size: 14px;
    line-height: 16px;
    color: #4C4C4C;
    font-family: 'Open Sans', sans-serif !important;
    font-weight: normal;
    letter-spacing: 0;
    padding: 23px 0 5px 32px;
    margin: 0;
}

.modalHeader {
    padding: 18px 0 18px 32px !important;
}

.modalTitle {
    font-weight: 600 !important;
    font-size: 16px !important;
    font-family: 'Open Sans', sans-serif !important;
    letter-spacing: 0;
    line-height: 24px;
}

.error {
    color: red;
    margin-bottom: 15px;
    text-align: center;
}