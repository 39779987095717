.chat_end_breaker {
    display: flex;
    align-items: center;
    text-align: center;
    color: #918F8F;
    font-size: 12px;
    margin-top: 24px;
    margin-bottom: 40px;
    width: calc(100% + 30px);
    position: absolute;
    left: -15px;
}
.chat_end_breaker::before,
.chat_end_breaker::after {
    content: '';
    flex: 1;
    border-bottom: 1px solid #918F8F;
}
.chat_end_breaker:not(:empty)::before {
    margin-right: 21px;
}
.chat_end_breaker:not(:empty)::after {
    margin-left: 21px;
}