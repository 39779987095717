.info_message {
    border: 1px solid #FB3B3B;
    padding: 22px 14px 21px 24px;
    display: flex;
    margin: 16px 0;
    position: relative;
}

.info_message_close {
    position: absolute;
    color: #FB3B3B;
    font-size: 16px;
    top: 16px;
    right: 16px;
    cursor: pointer;
}

.info_message_text {
    color: #FB3B3B;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 16px;
    padding: 0 24px;
}

.info_message_title {
    margin-bottom: 10px;
    font-weight: bold;
}

.info_message_icon {
    color: #FB3B3B;
    font-size: 24px;
    font-weight: lighter;
    margin: auto 0;
}