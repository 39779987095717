:global(.chatTransfer .rodal-dialog) {
    border-radius: 0 !important;
}

:global(.chatTransfer .rodal-close) {
    width: 24px;
    height: 24px;
    top: 12px;
    right: 18px;
    background-size: 100%;
    background: url("../../../assets/icons/modal-close-icon.svg") no-repeat;
}

:global(.chatTransfer .modal-data button) {
    margin-right: 0;
}

.chatTransferHeader {
    color: #061D53;
    font-size: 18px;
    font-family: Roboto;
    font-weight: 500;
    line-height: 18px;
    height: 34px;
    border-bottom: 1px solid #F1F4F6;
    padding-left: 24px;
    padding-top: 0;
    margin: 0 -15px 24px;
}

.chatTransferBottomLine {
    margin: 0 -15px 0 -20px;
}

.transferConversationTitle {
    color: #061D53;
    font-size: 16px;
    font-family: Roboto;
    font-weight: 400;
    line-height: 20px;
}

.transferConversationQueueTitle {
    color: #061D53;
    font-size: 14px;
    font-family: Roboto;
    font-weight: 500;
    text-transform: capitalize;
    line-height: 16px;
}

.queueNameTableRow {
    width: 430px;
    max-height: 250px;
    overflow: auto;
}

.queueName {
    color: #061D53;
    font-size: 14px;
    font-family: Roboto;
    font-weight: 400;
    text-transform: capitalize;
    line-height: 16px;
    cursor: pointer;
}

.radioButton {
    cursor: pointer;
    height: 34px; /*42px;*/
    vertical-align: middle;
    margin-right: 9px;
}

.transferButton {
    font-family: 'Open Sans', sans-serif !important;
    font-size: 14px !important;
    text-transform: capitalize;
    font-weight: 600 !important;
    letter-spacing: 0 !important;
    line-height: 16px !important;
    width: 95px !important;
    height: 36px !important;
    background: #061D53 !important;
    border-color: #061D53 !important;
    color: var(--whiteColor) !important;
}

.transferButton:hover {
    background: var(--whiteColor) !important;;
    color: #061D53 !important;
}

.transferButton:disabled {
    background: #061D53 !important;
    color: var(--whiteColor) !important;
}

.cancelButton {
    font-family: 'Open Sans', sans-serif !important;
    font-size: 14px !important;
    font-weight: 600 !important;
    letter-spacing: 0 !important;
    line-height: 16px !important;
    width: 95px !important;
    height: 36px !important;
    color: var(--blueColor) !important;
    border-color: var(--blueColor) !important;
    margin-right: 16px !important;
}

.cancelButton:hover {
    background: var(--blueColor) !important;
    color: var(--whiteColor) !important;
    border-color: var(--blueColor) !important;
}

.noteHeader {
    color: #061D53;
    font-size: 14px;
    font-family: Roboto;
    font-weight: 500;
    line-height: 16px;
}

.noteHeaderOptional {
    color: #061D53;
    font-size: 12px;
    font-family: Roboto;
    font-weight: 400;
    line-height: 16px;
    margin-left: 8px;
}

.noteText {
    color: #061D53;
    font-size: 14px;
    font-family: Roboto;
    font-weight: 500;
    line-height: 24px;
    word-wrap: break-word;
    width: 308px;
    height: 144px;
    resize: none;
    padding: 12px;
}

.noteCharCounter {
    width: 308px;
    text-align: right;
    margin-bottom: 32px;
}
