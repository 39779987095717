.new_messages_counter {
    position: absolute;
    right: 20px;
    top: 17px;
    background: #09AEB8;
    color: #fff;
    font-size: 14px;
    border-radius: 50%;
    padding: 2px 0;
    line-height: normal;
    width: 24px;
    height: 24px;
    font-weight: bold;
    text-align: center;
}

.chat_end_time {
    margin-left: 12px;
    float: right;
    font-weight: lighter;
}

.chat_end_total_time {
    position: absolute;
    right: 56px;
    top: -24px;
    text-align: right;
    font-size: 12px;
}

.chat_end_total_time .timer {
    font-size: 16px;
    color: #4C4C4C;
    margin-right: 0;
}

.timer {
    margin-right: 20px;
    margin-left: 5px;
    color: #918F8F;
}

.timer i {
    margin-right: 8px;
}

.contact_overlay {
    left: 15px;
    right: 15px;
    position: absolute;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.8);
    z-index: 101;
    display: flex;
    justify-content: left;
    padding-left: 10%;
    align-items: center;
}

.hr_line {
    height: 2px;
    position: absolute;
    top: 0;
    left: 0;
    background: rgb(239, 241, 244);
    width: 100%;
}