@import "../styles/variables.scss";

@import "../styles/typography.scss";

@import url('https://fonts.googleapis.com/css?family=Open+Sans:regular,bold,italic&subset=latin,latin-ext');

@import url('https://fonts.googleapis.com/css?family=Roboto:regular,bold,italic&subset=latin,latin-ext');

.custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: 0 0 0 1px lightgray;
    border: 1px solid red;
}

#root {
    height: 100%;
}

.main {
    background-color: rgb(245, 247, 246);
    height: 100%;
    padding-top: 1%;
}

.chat-window {
    padding-left: 15px;
    padding-right: 15px;
    width: 100%;
    height: 100%;
    background-color: #F5F6F7;
    border-radius: 10px;
    min-width: 1078px;
    max-width: 100% !important;
}

.chat-header {
    height: 90px;

    /*background-color: white;*/
}

.chat-body {
    height: calc(100% - 90px);
    overflow: hidden;
    background: white;
}

.chat-contacts {
    overflow: auto;
    border-right: 20px solid #F5F6F7;
    max-height: 100%;
}

.chat-messages {
    flex: 1 1 auto;
    position: relative;
    overflow: auto;
    height: 100%;
}

.chat-messages-list {
    height: 100%;

    /* !!! Required, for scrolling !!! */
    padding: 0 15px 0 15px;
    overflow-y: auto;
    overflow-x: hidden;
}

.chat-messages-send {
    flex: 2 0 auto;
    display: flex;
    min-height: 90px;
    border-top: 2px solid rgb(239, 241, 244);
    margin-left: 0;
    margin-right: 0;

    .message {
        width: 100%;
        display: flex;
        flex-direction: column;

        /*padding-top: 26px;*/
        /*padding-left: 34px;*/
        .error{
            color:red;
            font-size: 12px;
        }
        .inputArea {
            height: 100%;
            display: flex;
            font-size: 16px;

            .inputBox {
                margin: auto;
                padding-left: 25px;
                padding-top: 10px;
            }
            .inputBox.error{
                border: 1px solid red;
            }

            .buttonBox {
                margin: auto 30px auto auto;
                flex: 1 1 auto;
                padding: 5px;
                display: flex;
                align-items: center;
                justify-content: center;
            }
            @media (max-width: 1400px){
                .buttonBox {
                    margin-right:5px;
                }
            }
        }

    }

    .picture {
        font-size: 22px;
        color: gray;
        padding-top: 12px;
        padding-left: 30px;
    }

    .send {
        font-size: 20px;
        color: gray;
        display: flex;
        align-items: center;
    }

    textarea {
        width: 100%;
        border: 0;
    }
}

.chat-search {
    width: 100%;
    border: 1px solid rgba(215, 215, 215, 1);
    border-radius: 5px;
    margin-top: 22px;
    margin-left: 20px;
    height: 42px;
    padding-left: 45px;

    &::placeholder {
        font-size: 16px;
        color: rgba(215, 215, 215, 1);
    }
}

.input-search {
    font-size: 20px;
    color: #AAAAAA;
    position: absolute;
    left: 33px;
    top: 33px;
}

.chat-search-wrapper {
    position: relative;
}

.comments-icon {
    font-size: 55px;
    color: #70B603;
    margin-top: 15px;
}

.comments-icon-gray {
    color: rgb(242, 242, 242);
}

.offline-icon {
    font-size: 300px;
    color: rgb(242, 242, 242);
    margin-top: 15px;
}

.custom-control-input {
    &:checked ~ .custom-control-label::before {
        border-color: lightgray !important;
        background-color: #918F8F !important;
        outline: none;
        color: #918F8F;
    }

    &:focus {
        &:not(:checked) ~ .custom-control-label::before {
            border: lightgrey;
            outline: none !important;
        }

        &:checked ~ .custom-control-label::before {
            outline: none !important;
        }
    }
}

.custom-switch {
    .custom-control-label {
        text-transform: capitalize;

        &::after {
            background-color: #70B603;
        }
    }

    &.online {
        background: #D8F3DD;

        > .custom-control-label span {
            color: #2EA041;
        }
    }

    &.away {
        background: #f9e6b0;

        > .custom-control-label span {
            color: #c69500;
        }
    }

    background: #E8EFF0;
    font-style: normal;
    font-size: 13px;
    border-radius: 30px;
    padding: 5px 5px 5px 36px;
    width: 126px;
    height: 36px;
    position: relative;
    right: 20px;
    top: 3px;

    span {
        display: block;
        width: 70px;
        margin-right: 10px;
        color: #918F8F;
        font-weight: 800;
        font-size: 12px;
    }

    &.custom-switch-lg .custom-control-label {
        padding: 4px;

        &::before {
            height: 24px;
            width: 44px;
            left: -36px;
            top: 4px;
            border-radius: 16px;
            margin-top: -3px;
            margin-left: 6px;
            border: none;
        }

        &::after {
            width: 16px;
            height: 16px;
            top: 6px;
            left: -34px;
            border-radius: 36px;
            margin-top: -1px;
            margin-left: 7px;
        }
    }

    &.online.custom-switch-lg .custom-control-label {
        &::after {
            background: white;
        }

        &::before {
            background: #41C256;
        }
    }

    &.away.custom-switch-lg .custom-control-label {
        &::after {
            background: white;
        }

        &::before {
            background: #c69500;
        }
    }

    &.custom-switch-lg .custom-control-input:checked ~ .custom-control-label::after {
        transform: translateX(20px);
        margin-top: -1px;
        margin-left: 9px;
    }
}

.agentAwaySwitch {
    position: absolute;
    right: 16px;
    bottom: -5px;

    .control-input {
        outline: none;
        vertical-align: middle;
        margin-right: 5px;

        &:checked {
            background: #828282 !important;
            outline: none;
        }
    }

    .control-label {
        font-size: 13px;
    }
}

.top-sidebar-container {
    align-items: center;
    height: 100%;
}

.status-online {
    font-style: normal;
    color: #7F7F7F;
    font-size: 20px;
    margin-top: 8px;
    margin-left: 40px;
}

.current-status {
    font-style: normal;
    font-size: 12px;
    letter-spacing: 1.2px;
    color: #7F7F7F;
    clear: both;
}

.chat-contact {
    height: 75px;
    border-bottom: 2px solid rgba(239, 241, 244, 1);
    padding-left: 10px;
    padding-top: 7px;
    position: relative;

    .chat-contact-name {
        font-feature-settings: "kern";
        font-kerning: normal;
        font-size: 14px;
        font-style: normal;
        font-weight: bold;
        margin-top: 10px;
        height: 30px;
    }

    .chat-contact-time {
        margin-top: 8px;
        color: rgb(85, 85, 85);
        text-align: right;
        font-size: 16px;

        span {
            margin-left: 10px;
            padding: 5px 10px 5px 10px;
            border-radius: 22px;
            width: 11px;
            background-color: rgb(0, 201, 190);
            color: white;
        }
    }

    .chat-contact-preview {
        color: rgb(170, 170, 170);
        font-feature-settings: "kern";
        font-kerning: normal;
        font-size: 14px;
        font-weight: 600;

        /*text-transform: uppercase;*/
        font-style: normal;
        margin-top: -15px;
        margin-bottom: 16px;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: space-between;
        align-items: center;
    }

    &:hover {
        background-color: rgba(232, 239, 240, 0.4);
        cursor: pointer;

        &::after {
            content: "";
            position: absolute;
            bottom: -2px;
            top: 0;
            left: 0;
            right: 0;
            border-left: 6px solid #09AEB8;
        }

        .chat-contact-name {
            color: #263238;
        }
    }

    &.active {
        background-color: #E8EFF0;
        cursor: pointer;
    }

    &::after {
        content: "";
        position: absolute;
        bottom: -2px;
        top: 0;
        left: 0;
        height: inherit;
        border-left: 6px solid #09AEB8;
    }

    &.active::after {
        border-left: 6px solid #09AEB8;
    }

    &.missed {
        cursor: pointer;

        &::after {
            border-left: 6px solid rgb(240, 78, 78);
        }
    }

    &.rejected::after {
        border-left: 6px solid #918F8F;
    }

    &.active {
        &.disabled::after {
            border-left: 6px solid #918F8F;
        }

        .chat-contact-name {
            color: #263238;
        }
    }

    &.missed .chat-contact-name, &.unread .chat-contact-name {
        color: #263238;
    }
}

.chat-contact-incoming {
    height: 75px;
    border-bottom: 2px solid rgba(239, 241, 244, 1);
    position: relative;

    &::after {
        content: "";
        position: absolute;
        bottom: -2px;
        top: 0;
        left: 0;
        right: 0;
        border-left: 6px solid #09AEB8;
    }

    .chat-contact-name {
        font-feature-settings: "kern";
        font-kerning: normal;
        font-size: 14px;
        font-style: normal;
        margin-top: 10px;
        height: 30px;
    }

    .chat-contact-preview {
        color: #918F8F;
        font-size: 14px;
        line-height: 16px;
    }
}

.btn {
    border-radius: 2px !important;
}

.btn-accept {
    width: 94px;
    height: 32px;
    background-color: #51A8F1 !important;
    border-color: #51A8F1 !important;
    text-align: left !important;
    z-index: 10;

    &:hover, &:focus {
        background-color: #348BD4 !important;
        border-color: #348BD4 !important;
        text-align: left;
    }

    p {
        position: absolute;
        top: 4px;
        left: 32px;
        font-size: 14px;
    }

    i {
        font-size: 16px;
        position: absolute;
        top: 7px;
        left: 12px;
    }

    @media screen and (max-width: 1200px) {
        width: 80px;
        height: 27px;

        p {
            font-size: 12px;
            left: 30px;
            top: 5px;
        }

        i {
            font-size: 13px;
        }
    }
}

.btn-reject {
    width: 94px;
    height: 32px;
    background-color: #FB3B3B !important;
    border-color: #FB3B3B !important;
    text-align: left !important;
    z-index: 10;
    margin-left: 10px;

    &:hover, &:focus {
        background-color: #DA2323 !important;
        border-color: #DA2323 !important;
    }

    p {
        position: absolute;
        top: 4px;
        left: 36px;
        font-size: 14px;
    }

    i {
        font-size: 16px;
        position: absolute;
        top: 7px;
        left: 12px;
    }

    @media screen and (max-width: 1200px) {
        width: 80px;
        height: 27px;
        padding: 4px;

        p {
            font-size: 12px;
            left: 30px;
            top: 5px;
        }

        i {
            font-size: 13px;
        }
    }
}

.btn-end {
    position: relative;
    width: 112px;
    height: 32px;

    /*text-align: left !important;*/
    color: rgb(240, 78, 78) !important;
    background-color: white !important;
    border: 1px solid rgb(240, 78, 78) !important;
    border-radius: 2px;
    font-size: 16px !important;

    &:hover {
        background-color: #FFE0DF !important;
    }

    &:focus {
        outline: 0 !important;
        background-color: #000000;
    }

    p {
        position: absolute;
        top: 4px;
        right: 14px;
        font-size: 14px !important;
        font-weight: 600;
        color: #FB3B3B;

        /*letter-spacing: 0;*/
        /*line-height: 18px;*/
    }

    i {
        position: absolute;
        top: 7px;
        left: 10px;
        font-weight: lighter;
    }
}

.btn-close i {
    position: absolute;
    top: 7px;
    left: 10px;
    font-weight: lighter;
}

.btn-end.btn-close {
    position: relative;
    width: 121px;
    height: 32px;
    color: #061d53 !important;
    background-color: white !important;
    border: 1px solid #061d53 !important;
    border-radius: 2px;
    font-size: 16px !important;

    &:hover {
        background-color: #E8EFF0 !important;
    }

    p {
        color: #4C4C4C !important;
        top: 4px;
        right: 12px;
    }
}

.btn-transfer {
    width: 112px;
    text-align: left !important;
    height: 32px;
    color: #51A8F1 !important;
    background-color: white !important;
    border: 1px solid #51A8F1 !important;
    border-radius: 2px;
    font-size: 16px !important;
    margin-left: 20px;
    position: relative;

    &:focus {
        outline: 0 !important;
    }

    &:hover {
        background-color: #ddedff !important;
    }

    p {
        position: absolute;
        top: 4px;
        right: 14px;
        font-size: 14px !important;
        font-weight: 600;
        color: #51A8F1;

        /*letter-spacing: 0;*/
        /*line-height: 18px;*/
    }

    i {
        font-size: 17px;
        position: absolute;
        top: 7px;
        left: 12px;
    }
}

.chat-window .remove-button {
    top: 15px;
    right: 32px;
    z-index: 10;
    position: absolute;
}

.remove-button .btn {
    width: 102px;
    height: 32px;
    font-size: 14px;
    font-weight: 600;
    line-height: 18px;
    color: #4C4C4C;
    border: 1px solid #4C4C4C;
    background-color: #fff;

    @media screen and (max-width: 1200px) {
        width: 86px;
        height: 27px;
        font-size: 12px;
        padding: 4px;
    }

    &:hover {
        background-color: #E8EFF0;
    }
}

.end-chat {
    text-align: right;
    margin: auto 0;
    padding-right: 25px;
}

.message-left {
    text-align: left;
    margin: 20px 0;
}

.message-right {
    text-align: right;
    margin: 20px 0;
}

.message-left p,
.message-left .message-left-body {
    background-color: rgb(241, 243, 244);
    padding: 10px 16px 10px 16px;
    border: 1px solid white;
    border-radius: 10px;
    color: rgb(85, 85, 85);
    width: fit-content;
    margin-bottom: 10px;
    font-size: 12px;
}

.message-right p {
    background-color: #E8EFF0;
    padding: 10px 16px 10px 16px;
    border: 1px solid white;
    border-radius: 10px;
    color: #176D74;
    text-align: left;
    width: fit-content;
    display: inline-block;
    margin-bottom: 10px;
    font-size: 12px;
}

.message-center {
    text-align: center;

    p {
        font-size: 13px;
        color: #777777;
        font-weight: bold;
    }
}

.conversation {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    justify-content: space-between;
}

.message-time {
    color: #918F8F;
    opacity: 0.5;
    font-size: 11px;
    display: block;
    margin-top: -10px;
    margin-right: 15px;
    margin-left: 15px;
}

.active-chats {
    /*font-size: 15px !important;*/
    margin-left: 8px;
    margin-top: 2px;
    font-size: 12px;
    color: #918F8F;
}

.messages-header {
    height: 90px;
    border-bottom: 2px solid #F5F6F7;
}

.customer-name {
    font-size: 16px;
    padding-top: 20px;
    padding-left: 30px;

    h3 {
        font-size: 15px;
    }

    p {
        font-size: 14px;
        color: #7F7F7F;
    }
}

.contact-button {
    padding: 10px 10px 5px 5px !important;
}

.contact-buttons {
    position: absolute;
    top: 19px;
    right: 26px;
    z-index: 10;

    button {
        position: relative;
        font-size: 14px !important;
        font-weight: 600;
    }
}

.message textarea:focus {
    outline: none;
}

.send-message {
    padding: 5px;
    outline: 0;
    background: transparent;
    cursor: pointer;
    border-radius: 2px;
    border: 1px solid transparent;
    transition: border-color .3s ease;
    font-size: 0;

    &:focus,
    &:focus-visible,
    &:active {
        border: 1px solid #09AEB8;
    }

    i {
        font-size: 29px;
        color: #09AEB8;
    }
}

.last-agent p {
    border-bottom-right-radius: 0;
}

.last-consumer p {
    border-bottom-left-radius: 0;
}

.dot {
    display: inline-block;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    margin-right: 3px;
    background: #d7d7d7;
    animation: wave 1.3s linear infinite;

    &:nth-child(2) {
        animation-delay: -1.1s;
    }

    &:nth-child(3) {
        animation-delay: -0.9s;
    }
}

@keyframes wave {
    0%, 60%, 100% {
        transform: initial;
    }

    30% {
        transform: translateY(-15px);
    }
}

.message textarea {
    max-height: 300px;
    resize: none;
    font-size: 15px;
}

.conversation-row {
    padding: 0 !important;
}

.btn-queue {
    width: 135px;
    text-align: left !important;
    height: 40px;
    font-size: 14px !important;
    margin-top: 25px;
    font-weight: bold;

    p {
        margin-top: 3px;
    }
}

.modal-transfer-head {
    font-size: 18px;
    margin: 5px;
}

.modal-transfer-line {
    margin: 18px -15px;
}

.modal-transfer-bottom-line {
    margin: 18px -15px 18px -20px;
}

.rodal-close {
    top: 22px;
    right: 28px;
    background: url("../assets/icons/close.png");
    background-size: 100%;
    background-repeat: no-repeat;

    &:before, &:after {
        display: none;
    }
}

.rodal-dialog {
    border-radius: 5px !important;
}

.modal-data {
    font-size: 14px;
    padding-left: 5px;

    label {
        margin-left: 10px;
    }

    button {
        margin-right: 10px;
        font-size: 12px;

        &.btn-info {
            background-color: rgba(22, 155, 213, 1);
        }

        &.btn-outline-info {
            color: rgba(22, 155, 213, 1);
            border-color: rgba(22, 155, 213, 1);
        }
    }
}

.Toastify__close-button {
    display: none !important;
}

.Toastify__toast--default {
    background-color: rgba(36, 170, 247, 1) !important;
    color: white !important;
    font-size: 12px !important;
}

.Toastify__toast-container {
    width: 228px !important;
}

.Toastify__toast {
    min-height: 42px !important;
}

.Toastify__toast-body {
    padding: 0 6px !important;

    i {
        margin-right: 10px;
        font-size: 22px;
    }

    span {
        display: block;
        position: absolute;
        top: 12px;
        left: 49px;
    }
}

.btn {
    &:focus, &:active:focus, &.active:focus {
        outline: none;
        box-shadow: none !important;
    }
}

.rodal-dialog {
    height: min-content !important;
}

.btn-cancel:hover {
    background-color: white !important;
}

.templates-block {
    border-left: 2px solid rgb(239, 241, 244);
    margin-bottom: -15px;
    margin-left: -20px;
    text-align: center;
}

.agent-overlay {
    /*left: 15px;*/
    /*right: 15px;*/
    position: absolute;
    height: 100%;
    width: 712px;
    background-color: rgba(255, 255, 255, 0.8);
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;
}

.conversation-overlay {
    left: 0;
    right: 0;
    position: absolute;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.8);
    z-index: 101;
    display: flex;
    justify-content: center;
    align-items: center;

    .overlay-content {
        text-align: center;
        height: 350px;
    }
}

.label-dev {
    background: #c69500;
    color: #fff;
    padding: 0 10px;
    position: absolute;
    top: 0;
    left: 115px;
    z-index: 101;

    &.staging {
        background: #0bb48b;
    }
}

.label-dev-queue {
    font-weight: bold;
    position: absolute;
    background: #74bce5;
    border-radius: 3px;
    z-index: 10;
    color: #fff !important;
    margin-top: 8px;
    margin-left: -14px;
    padding: 5px;
}

.panel-dev {
    position: absolute;
    top: 0;

    /*right: 0;*/
    left: 0;
    z-index: 101;

    .dropdown-toggle {
        color: white;
    }
}

.button-dev {
    margin-right: 10px;
    font-size: 12px;
}

.templates-open {
    display: inline-block;
    border: none;
    height: 35px;
    width: 35px;
    margin-top: 1px;
    background-size: 100%;
    background: url("../assets/icons/template.png") no-repeat;
}

.custom-alert-container {
    position: absolute;
    top: 20%;
    left: 65%;
    transform: translate(-50%, -50%);
    z-index: 10;
}

.custom-alert-children-container {
    display: flex;
    justify-content: space-around;
}

.modal-header {
    border-bottom: 1px solid #EFEFEF !important;
}

.modal-body {
    padding: 0 !important;
}

.modal-content {
    height: 100%;
}

.modal-backdrop.show {
    opacity: 0.6 !important;
    background-color: #918F8F !important;
}

.category-inputs > .react-select__menu {
    width: 540px !important;
    margin-top: 2px !important;
}

.react-select__menu-list {
    overflow-y: scroll !important;
    overflow-x: inherit !important;
    padding: 0 !important;
    max-height: 195px !important;
}

.react-select__control {
    /*width: 368px !important;*/
    height: 48px;
}

.react-select__menu {
    width: 368px !important;
}

.react-select__single-value {
    font-family: "Open Sans", Arial, sans-serif;
    font-size: 14px !important;
    font-weight: 600 !important;
    color: #061d53 !important;
    letter-spacing: 0;
    line-height: 16px;
}

.react-select__option {
    color: #061d53 !important;
    font-family: 'Roboto', sans-serif !important;
    font-size: 14px !important;
    letter-spacing: 0;
    line-height: 18px;
    font-weight: 400;
    padding: 8px 30px 8px 13px !important;
}

.react-select__option--is-selected {
    background-color: #D4DFE0 !important;
    color: #061d53 !important;
}

.react-select__option--is-focused {
    background-color: #EBF8F9 !important;
    color: #061d53 !important;
}

.react-select__option {
    &:hover, &:focus {
        color: #061d53 !important;
        background-color: #EBF8F9 !important;
    }
}

.free-text-hint {
    width: 508px;
    font-size: 12px;
    color: #637580;
    display: flex;
    font-weight: 400;
    justify-content: space-between;

    &.error {
        color: red;
    }
}

.css-tlfecz-indicatorContainer {
    color: #000;
}

.free-text-input {
    border-color: #061d53 !important;
    color: #061d53 !important;
    min-height: 48px;

    &.error {
        border-color: red !important;
    }
}

.react-select__clear-indicator {
    color: #000 !important;
}

.category-cancel:hover, .category-submit {
    background: #061D53 !important;
    border-color: #061D53 !important;
}

.modal-footer {
    margin-top: 50px;
    height: 84px !important;
}

.modal-open .modal-footer .btn.category-submit:hover {
    border-color: #061D53 !important;
    color: #061D53 !important;
    background-color: #fff !important;
}

.modal-open .modal-footer .btn.category-submit:disabled:hover {
    color: #fff !important;
    background-color: #061D53 !important;
}

.category-cancel {
    border-color: #061D53 !important;
    color: #061D53 !important;
    background: #fff;
}

.category-modal-close, .react-select__clear-indicator, .plus-div, .react-select__multi-value__label {
    color: #061D53 !important;
}

.react-select__multi-value__label-custom {
    border-radius: 40px;
    color: #061D53;
    font-size: 14px;
    overflow: hidden;
    padding: 3px 3px 3px 6px;
    text-overflow: ellipsis;
    white-space: nowrap;
    box-sizing: border-box;
    border: 1px solid;
    margin: 5px;
}

.react-select__multi-value__remove {
    padding-left: 8px !important;
    padding-right: 8px !important;

    &:hover {
        background: none !important;
        color: #000 !important;
    }
}

.category-modal {
    &.modal-title-custom {
        color: #061d53;
        font-weight: 500 !important;
        font-size: 18px !important;
        line-height: 24px !important;
    }

    &.title {
        color: #061d53;
        font-size: 16px;
        line-height: 20px;
        font-weight: 400;
        width: 427px;
    }
}

.transcript-load {
    margin: 10% auto;
    text-align: center;
    color: #aaaaaa;

    button {
        margin-top: 20px;
    }
}

.transcript-load-more {
    width: 100%;
    padding: 3%;
    text-align: center;
    color: #aaaaaa;
    border-bottom: 1px solid rgba(239, 241, 244, 1);

    button {
        margin-top: 10px;
    }
}

.switch-menu {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: space-between;
    align-items: center;
    border-right: 20px solid #F5F6F7;
    border-bottom: 2px solid #F5F6F7;

    /*background: rgb(233, 235, 236);*/
}

.top-menu-bottom-line {
    color: #E8EFF0;
    margin: -5px;
    border: 1px solid #E8EFF0;
}

.chat-list {
    font-size: 16px;
}

.labels {
    margin-left: 24px;
    display: flex;
    align-items: center;
}

.header-container {
    height: 90px;
    background-color: white;
}

.contacts-head {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: space-around;
}

.no-chats {
    text-align: center;
    margin-top: 37px;
    color: #918F8F;
    font-size: 12px;
}

.disabled {
    pointer-events: none;
    opacity: 0.4;
}

.modal-data button.btn-outline-btn {
    color: #061D53;
    border-color: #061D53;
    font-size: 16px;
    font-weight: 700;

    &.apply {
        background-color: #061D53;
        color: #ffffff;
    }

    &.disabled {
        background: #737373;
        border-color: #737373;
        color: #fff;
    }
}

.template-variable {
    color: #09AEB8 !important;
}

.dropdown-toggle {
    color: #176D74;
}

.template-button {
    .dropdown-item {
        font-size: 14px;
        padding: 3px 20px;
    }

    .dropdown-toggle {
        &.btn.btn-link {
            padding: 28px 24px;
            font-weight: 700;
            font-size: 16px;

            &:hover, &:focus {
                text-decoration: none;
                color: #176D74;
            }
        }

        color: #176D74;
        position: relative;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        align-content: center;
        border-right: 2px solid rgb(239, 241, 244);
    }
}

.templates-toggle {
    height: 100%;

    .btn {
        height: 100%;
        font-size: 16px;
    }

    .dropdown-toggle .dropdown-icon {
        margin-left: 8px;
        content: url("../assets/icons/templates-down-arrow.svg");
    }

    .dropdown-toggle::after {
        margin-left: .955em;
        display: none;
    }

    &.show .dropdown-toggle .dropdown-icon {
        content: url("../assets/icons/templates-up-arrow.svg");
    }

    .dropdown-item {
        font-size: 16px;
        padding: 4px 24px;
    }

    .dropdown-item:hover {
        background: #09AEB8;
        color: #fff;
    }

    .dropdown-menu {
        border: 1px solid #E8EFF0 !important;
        overflow: auto;
    }
}

.templates-modal {
    .modal-transfer-head {
        font-family: "Open Sans", Arial, sans-serif;
        color: #061D53;
        font-weight: 700;
        line-height: 16px;
    }

    .rodal-close {
        top: 22px;
        right: 28px;
        background: url("../assets/icons/templates-close-modal.svg");
        background-size: 100%;
        background-repeat: no-repeat;
    }

    input {
        border: 1px solid #061D53;
        color: #061D53;

        &:focus {
            box-shadow: none;
            border: 1px solid #000000;
            box-shadow: none;
        }
    }
}

span.underline-bold {
    text-decoration: underline;
    font-weight: bold;
}

.category-inputs, .category-modal.title, .modal-title-custom {
    font-family: 'Roboto', sans-serif !important;
}

.modal-dialog {
    max-width: 712px !important;
}

.modal-footer .categories_buttons {
    padding-right: 0 !important;
    margin: 0 !important;
}

.categories_form {
    width: 540px;
}

.categories-modal {
    .form-control {
        font-size: 16px;
    }

    .btn {
        padding: 6px 12px;
    }
}

.category-inputs {
    width: 540px !important;

    /*height: 46px !important;*/
    border-color: #061d53;

    font-size: 16px;

    &.error {
        border-color: red;
    }
}

.categories_buttons > div > button {
    font-family: 'Roboto', sans-serif !important;
}

.react-select__multi-value {
    /*flex: none!important;*/
    max-width: 155px;
    margin-right: 10px !important;
}

.react-select__placeholder {
    color: #A4A9AB !important;
    font-size: 14px !important;
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    line-height: 24px;
}

.free-text-input::placeholder {
    color: #A4A9AB !important;
}

button {
    &.category-cancel, &.category-submit {
        height: 36px !important;
    }

    &.category-cancel {
        width: 75px !important;
    }

    &.category-submit {
        width: 63px !important;
    }
}

.categories_buttons_container {
    width: 155px;
    margin: 10px;
}

.react-select__value-container {
    display: flex !important;
}

.form-group-custom {
    border: 1px solid #061d53;
    width: 540px;
    position: relative;

    .react-select__control {
        border: none !important;
        height: 45px;
        width: 538px !important;
    }

    > label {
        position: absolute;
        top: -10px;
        left: 20px;
        font-size: 12px;
        font-weight: 400;
        line-height: 12px;
        font-family: 'Roboto', sans-serif;
        background-color: white;
        z-index: 100;
        padding: 3px;
    }
}
